<template>
	<el-dialog :title="$dict('车队司机修改')" top="1vh" width="600px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form label-width="140px">
			<el-form-item :label="$dict('备注')">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<div style="text-align:center;">
				<el-button @click="sub" type="primary">{{ $dict('提交') }}</el-button>
			</div>
		</el-form>
  	</el-dialog>
</template>
<script>
	export default {
		props: {
			data: Object,
			is_show: Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page: false,

				form:{
					mark:'',
				},
			};
		},
		watch: {
			is_show(new_data) {
				if (new_data) {

					//打开弹出层
					this.is_show_in_page = true;

					//初始化
					this.init();

				} else this.is_show_in_page = false;
			},
		},
 	 	methods: {

			//提交
			sub(){

				//询问
				this.$my.other.confirm({
					content:"点击确定修改",
					confirm:()=>{
						this.$my.net.req({
							loading:true,
							data:{
								mod:'truck_carrier',
								ctr:'truck_carrier_bind_truck_driver_edit_by_member',
								truck_carrier_bind_truck_driver_id:this.data.id,
								mark:this.form.mark,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"保存成功"
								});
								this.is_show_in_page=false;
								this.$emit('edited')
							},
						});
					}
				});
			},

			//初始化
			init() {

				this.form.mark=this.data.mark
			},
  		},
	};
</script>