<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="140px">
			<el-form-item class="el_form_item" :label="$dict('司机手机')">
				<el-input class="el_input" v-model="form.driver_user_tel"></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('备注')">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">{{ $dict('查询') }}</el-button>
				<el-button type="success" @click="add_view_open()">{{ $dict('添加') }}</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column :label="$dict('添加时间')" prop="create_time_text" width="145"></el-table-column>
				<el-table-column :label="$dict('修改时间')" prop="update_time_text" width="145"></el-table-column>
				<el-table-column :label="$dict('司机')" width="">
					<template slot-scope="scope">
						{{scope.row.driver_user_info.tel}}/{{scope.row.driver_user_info.name}}
					</template>
				</el-table-column>
				<el-table-column :label="$dict('备注')" prop="mark" width="300"></el-table-column>
				<el-table-column :label="$dict('状态')" prop="status_text" width="90"></el-table-column>

				<!-- 操作行 -->
				<el-table-column :label="$dict('操作')" width="120">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text" >{{ $dict('修改') }}</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text" style="color:red">{{ $dict('删除') }}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add.is_show" 
			@added="add_view_close"
		></add>

		<!-- 添加弹出层 -->
		<edit 
			:is_show="edit.is_show" 
			:data="edit.data" 
			@edited="edit_view_close"
		></edit>
	</div>
</template>

<script>
	import add from './add.vue'
	import edit from './edit.vue'
	export default {
		components:{
			add,
			edit,
		},
		data() {
			return {

				//搜索条件
				form: {
					driver_user_tel:'',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					is_show:0,
					data:{},
				},
			}
		},
		created() {

			//读取页面数据
			this.page_ser()
		},
		methods: {

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_carrier',
								ctr:'truck_carrier_bind_truck_driver_del_by_member',
								truck_carrier_bind_truck_driver_id:item.id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.page_ser()
			},

			//修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(){
				this.edit.is_show=0
				this.page_ser()
			},

			//搜索
			page_ser(){
				this.page.p=1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){
				this.page.num=num
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				this.page.p=p
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_carrier',
						ctr:'truck_carrier_bind_truck_driver_list_by_member',
						is_get_driver_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//取出各种info
							item.driver_user_info=item.driver_user_info[0]

							//创建/修改时间
							item.create_time_text=this.$my.other.totime(item.create_time);
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态(1:审核中,2:审核通过,3:审核未通过)
							switch(item.status){
								case '1':item.status_text="审核中";break;
								case '2':item.status_text="审核通过";break;
								case '3':item.status_text="审核未通过";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 25%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>