<template>
	<el-dialog :title="$dict('车队司机添加')" top="1vh" width="600px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form label-width="140px">
			<el-form-item :label="$dict('车队')">
				<el-input v-model="truck_carrier_info.name" disabled></el-input>
			</el-form-item>
			<el-form-item :label="$dict('司机手机')">
				<el-input v-model="truck_driver_info.tel" :disabled="truck_driver_info.driver_user_id?true:false">
					<el-button slot="append" @click="truck_driver_ser">{{ $dict('搜索') }}</el-button>
					<el-button slot="append" @click="truck_driver_clear">{{ $dict('清空') }}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item :label="$dict('查找结果')">
				<el-input v-model="truck_driver_info.name" disabled></el-input>
			</el-form-item>
			<el-form-item :label="$dict('备注')">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<div style="text-align:center;">
				<el-button @click="sub" type="primary">{{ $dict('提交') }}</el-button>
			</div>
		</el-form>

  	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	export default {
		props: {
			is_show: Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page: false,

				form:{
					mark:'',
				},

				//司机
				truck_driver_info:{
					tel:'',
					name:'',
					driver_user_id:'',
				},
			};
		},
		computed:{
			...mapState(['truck_carrier_info'])
		},
		watch: {
			is_show(new_data) {
				if (new_data) {

					//打开弹出层
					this.is_show_in_page = true;

					//初始化
					this.init();

				} else this.is_show_in_page = false;
			},
		},
 	 	methods: {

			//提交
			sub(){

				if(!this.truck_driver_info.driver_user_id.trim()){
					this.$my.other.msg({
						type: "warning",
						str: "缺少司机",
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'添加",
					confirm:()=>{
						this.$my.net.req({
							loading:true,
							data:{
								mod:'truck_carrier',
								ctr:'truck_carrier_bind_truck_driver_add_by_member',
								driver_user_id:this.truck_driver_info.driver_user_id.trim(),
								mark:this.form.mark,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"保存成功"
								});
								this.is_show_in_page=false;
								this.$emit('added')
							},
						});
					}
				});
			},

			//司机查找
			truck_driver_clear(){//清空
				this.truck_driver_info.tel=''
				this.truck_driver_info.name=''
				this.truck_driver_info.driver_user_id=''
			},
			truck_driver_ser(){//搜索

				if(!this.$my.check.is_tel(this.truck_driver_info.tel)){
					this.$my.other.msg({
						type: "warning",
						str: "缺少司机手机",
					});
					return;
				}

				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'truck_driver_ser',
						driver_user_tel:this.truck_driver_info.tel,
					},
					callback:(data)=>{
						this.truck_driver_info.name=data.name
						this.truck_driver_info.driver_user_id=data.id
					}
				})
			},

			//初始化
			init() {
				this.form.mark=''
				this.truck_driver_clear();
			},
  		},
	};
</script>
<style lang="scss" scoped>

	.bottomBtns {
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}
</style>